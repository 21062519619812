<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-contacts p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Контакты
          </h1>
          <div class="divider" />
          <p class="ta--center mb-l">
            Мы предоставляем всю информацию и всегда на связи!
          </p>
          <google-map-loader
            class="mb-l"
            :map-config="mapConfig"
          >
            <template slot-scope="{ google, map }">
              <google-map-marker
                v-for="marker in markers"
                :key="marker.id"
                :marker="marker"
                :google="google"
                :map="map"
              />
            </template>
          </google-map-loader>
          <div class="wrapper-contacts mb-l">
            <p class="contact-info">
              <icon-call class="icon" />
              <a href="tel:+74951094308">8 (495) 109-43-08</a>
            </p>
            <p class="contact-info">
              <icon-mail class="icon" />
              <a href="mailto:info@clicktoid.ru">
                info@CLICKTOID.RU
              </a>
            </p>
          </div>
          <p class="contact-info mb-xl">
            <icon-location class="icon" />
            <span>117133, г. Москва, ул Академика Варги, дом 8, к. 1, этаж 7, офис 703.</span>
          </p>
          <p class="fs--medium fw--bold mb-l">
            Форма обратной связи:
          </p>
          <form class="form mb-l">
            <input-text placeholder="Имя" required />
            <input-text placeholder="E-mail" required />
            <input-text class="input-message mb-s" placeholder="Ваше сообщение" />
            <app-button
              class="btn-submit"
              title="Отправить"
              description="Отправка сообщения"
              primary
            >
              <icon-arrow-right class="icon" />
            </app-button>
          </form>
          <p class="required fw--light fs--small mb-xl">
            <span class="symbol">*</span> - Обязательные поля
          </p>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import { mapSettings } from "@/constants/mapSettings"

import MainTemplate from '@/layouts/MainTemplate'
import AppButton from '@/components/common/AppButton'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'
import GoogleMapLoader from '@/components/googlemap/GoogleMapLoader'
import GoogleMapMarker from '@/components/googlemap/GoogleMapMarker'
import InputText from '@/components/input/InputText'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'
import IconCall from '@/assets/img/icons/call.svg'
import IconMail from '@/assets/img/icons/mail.svg'
import IconLocation from '@/assets/img/icons/location.svg'

export default {
  name: 'Contacts',
  components: {
    MainTemplate,
    AppButton,
    SecondaryPagesBackground,
    GoogleMapLoader,
    GoogleMapMarker,
    InputText,
    IconArrowRight,
    IconCall,
    IconMail,
    IconLocation
  },
  data () {
    return {
      markers: [
        {
          id: 1,
          position: { lat: 55.623110, lng: 37.604330 }
        },
      ]
    }
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter
      };
    },
    mapCenter() {
      return this.markers[0].position;
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-contacts {
    .wrapper {
      max-width: 640px;
      .wrapper-contacts {
        display: flex;
        align-items: center;
        @include max-w-xs {
          margin-bottom: 0;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .contact-info {
        margin-right: 28px;
        display: flex;
        align-items: center;
        @include max-w-xs {
          margin-right: 0;
          margin-bottom: 12px;
        }
        .icon {
          margin-right: 18px;
          path {
            fill: #FF5064;
          }
        }
      }
      .form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        @include max-w-xs {
          input {
            grid-column: 1 / 3;
          }
        }

        .input-message, .btn-submit {
          grid-column: 1 / 3;
        }
        .btn-submit {
          .icon {
            path {
              stroke: $white;
            }
          }
        }
      }
      .required {
        color: $gray;
        .symbol {
          color: #FF5064;
        }
      }
    }
  }
</style>
